import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
import {getMainParentRouteName} from "@/lib/lib";

const unitHwTypeTransformation = {
    xcargo: 'xgrafana',
    tive: 'xgrafana',
    geometris: 'obd',
    suntech: 'obd',
    pro_cargo_vision: 'pro_cargo_vision',
}

function getUnitDefaultRouteParams(unitId) {
    let unit = store.getters.unitsByIds[unitId]
    localStorage.setItem(store.getters.getUserStorageKey + '.unit.active', unitId)
    let template = unitHwTypeTransformation[unit?.hw_type] || unit?.hw_type
    let routeName = consts.routerNames?.units?.type[template]

    //if(store.getters.isUnitsFullLoad){
    if (!routeName) {
        if (unit?.video) {
            //template = 'xvision'
            routeName = consts.routerNames?.units?.type?.xvision
        } else if (unit?.reefer_type) {
            //template = 'xcool'
            routeName = consts.routerNames?.units?.type?.xcool
        } else {
            //template = 'xtrack'
            routeName = consts.routerNames?.units?.type?.xtrack
        }
    }
    //}
    if (!unitId) routeName = consts.routerNames.units

    return {
        routeName,
        params: {
            unitId,
            //template
        }
    }
}

function clearQueryFilter(query) {
    delete query.hideEvents
    return query
}

const defaultSetRoute = {
    units: (route, router) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('units', route);
            return false;
        }
        router.push({
            name: consts.routerNames.units.main,
            params: {},
            query: {}
        })
    },
    returnToParent: (route, router) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn(consts.routerNames.units.main + ' returnToParent', route);
            return false;
        }
        let name = route.meta?.parentRouteName || consts.routerNames.units.main;
        let query = clearQueryFilter(route.query)
        router.push({
            name: name,
            params: {
                ...route.params,
            },
            query
        })
    },
    unitsAll: (route, router) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('units', route);
            return false;
        }
        let query = clearQueryFilter(route.query)
        router.push({
            name: consts.routerNames.units.all.main,
            params: {},
            query
        })
    },
    unitId: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitId', route);
            return false;
        }
        if (store.getters?.unitsOnMap?.length) {
            store.commit('setUnitsShowOnMap', {
                showOnMap: false,
                unitIds: store.getters?.unitsOnMap
            })
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        let query = clearQueryFilter(route.query)
        router.push({
            name: routeParams.routeName.main,
            params: {
                ...routeParams?.params,
            },
            query
        })
    },
    liteTemplate: (route, router, lite, noList = false) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('liteTemplate', route);
            return false;
        }
        let routeName = route.name.split('.');
        let index = routeName.findIndex(i => i == 'lite');
        if (!lite && (index > 0)) {
            routeName.splice(index, 1)
        } else if (lite && index == -1) {
            routeName.push('lite')
        }
        router.push({
            name: routeName.join('.'),
            params: {
                ...route?.params,
                noList: noList ? 'no-list' : '',
            },
            query: {...route.query}
        })
    },
    unitEdit: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitEdit', route);
            return false;
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        router.push({
            name: routeParams.routeName.edit,
            params: {
                ...route.params,
                unitId,
            },
            query: {...route.query}
        })

    },
    unitInfo: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitInfo', route);
            return false;
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        router.push({
            name: routeParams?.routeName.info,
            params: {
                ...routeParams?.params,
            },
            query: {...route.query}
        })
    },
    unitsAllUnitInfo: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitInfo', route);
            return false;
        }
        router.push({
            name: consts.routerNames.units.all.info,
            params: {
                unitId,
            },
            query: {...route.query}
        })
    },
    unitReefer: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitReefer', route);
            return false;
        }
        router.push({
            name: consts.routerNames.units.type.xcool.reefer,
            params: {
                unitId,
            },
            query: {...route.query}
        })
    },
    unitStationAnalytics: (route, router, unitId, name) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitStationAnalytics', route);
            return false;
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        router.push({
            name: routeParams.routeName[name],
            params: {
                ...routeParams.params
            },
            query: {...route.query}
        })
    },
    unitGenerator: (route, router, unitId, name) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitGenerator', route);
            return false
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        router.push({
            name: routeParams?.routeName[name],
            params: {
                ...routeParams.params,
            },
            query: {...route.query}
        })
    },
    unitsFilter: (route, router, filter) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitsFilter', route);
            return false
        }
        let unitId = route.params?.unitId
        let eventXid = route.params?.eventXid
        router.push({
            name: route.name,
            params: {
                unitId,
                eventXid,
            },
            query: {...filter}
        })
    },
    liveVideo: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('liveVideo', route);
            return false
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        let query = clearQueryFilter(route.query)
        router.push({
            name: routeParams.routeName.liveVideo,
            params: {
                ...routeParams.params
            },
            query
        })
    },
    unitSnapshots: (route, router, unitId, eventXid) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
            if (this.$isDevelopment) console.warn('unitId', route);
            return false;
        }
        let isLite = route.name.split('.').includes('lite')
        let noList = route.params.noList
        let routeParams = getUnitDefaultRouteParams(unitId)
        let query = clearQueryFilter(route.query)
        router.push({
            name: routeParams?.routeName?.snapshots + (isLite ? '.lite' : ''),
            params: {
                ...routeParams.params,
                unitId,
                eventXid,
                noList
            },
            query
        })
    },
    command: (route, router, unitId, eventXid) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('event', route);
            return false
        }
        console.log(router, unitId, eventXid);
        return false;
        // let isLite = route.name?.split('.')?.includes('lite');
        // let noList = route?.params?.noList
        // let routeParams = getUnitDefaultRouteParams(unitId)
        // let query = clearQueryFilter(route.query)
        // router.push({
        //     name: routeParams?.routeName?.event + (isLite ? '.lite' : ''),
        //     params: {
        //         ...routeParams.params,
        //         eventXid,
        //         noList
        //     },
        //     query
        // })
    },
    event: (route, router, unitId, eventXid) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('event', route);
            return false
        }
        let isLite = route.name?.split('.')?.includes('lite');
        let noList = route?.params?.noList
        let routeParams = getUnitDefaultRouteParams(unitId)
        let query = clearQueryFilter(route.query)
        router.push({
            name: routeParams?.routeName?.event + (isLite ? '.lite' : ''),
            params: {
                ...routeParams.params,
                eventXid,
                noList
            },
            query
        })
    },
    'video-event': (route, router, unitId, eventXid) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('video-event', route);
            return false
        }
        let isLite = route.name?.split('.')?.includes('lite');
        let noList = route?.params?.noList
        let routeParams = getUnitDefaultRouteParams(unitId)
        let query = clearQueryFilter(route.query)
        router.push({
            name: routeParams?.routeName?.videoEvent + (isLite ? '.lite' : ''),
            params: {
                ...routeParams.params,
                eventXid,
                noList
            },
            query
        })
    },
    'video-request': (route, router, unitId, eventXid) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('video-request', route);
            return false
        }
        let isLite = route.name.split('.').includes('lite')
        let noList = route.params.noList
        let routeParams = getUnitDefaultRouteParams(unitId)
        let query = clearQueryFilter(route.query)
        router.push({
            name: routeParams.routeName.videoRequest + (isLite ? '.lite' : ''),
            params: {
                ...routeParams.params,
                eventXid,
                noList
            },
            query
        })
    },
    fastGeoitem: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('fastGeoitem', route);
            return false
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        router.push({
            name: routeParams.routeName.fastGeozone,
            params: {
                ...routeParams.params,
            },
            query: {...route.query}
        })
    },
    track: (route, router, unitId, date = store.getters.getDateToday) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('track', route);
            return false
        }
        router.push({
            name: consts.routerNames.tracks.main,
            params: {
                unitId,
                date,
            },
            query: {}
        })
    },
    unitTrackXid: (route, router, date, type, trackXid, timeFrom, timeTo, trackColor, filter) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('trackXid', route);
            return false
        }
        let unitId = route?.params?.unitId
        let routeParams = getUnitDefaultRouteParams(unitId)
        trackColor = trackColor ? 'track-color' : ''
        router.push({
            name: routeParams.routeName.track,
            params: {
                ...routeParams.params,
                date,
                type,
                trackXid,
                timeFrom,
                timeTo,
                trackColor,
            },
            query: {
                ...route.query,
                ...filter,
            }
        })
    },
    unitCommand: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitCommand', route);
            return false
        }
        router.push({
            name: consts.routerNames.units.commands.main,
            params: {
                unitId,
            },
            query: {...route.query}
        })
    },
    unitsGroupCommand: (route, router, groupId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitsGroupCommand', route);
            return false
        }
        router.push({
            name: consts.routerNames.units.commands.group,
            params: {
                groupId,
            },
            query: {...route.query}
        })
    },
    unitsLocator: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitsLocator', route);
            return false
        }
        router.push({
            name: consts.routerNames.units.locator,
            params: {
                unitId,
            },
            query: {...route.query}
        })
    },
    unitChat: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitChat', route);
            return false
        }
        router.push({
            name: consts.routerNames.units.chat,
            params: {
                unitId,
            },
            query: {...route.query}
        })
    },
    unitAOVXSwitch: (route, router, unitId, routeName) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitAOVXSwitch', route);
            return false;
        }
        router.push({
            name: routeName,
            params: {
                ...route.params,
                unitId,
            },
            query: {...route.query}
        })
    },
    unitChangePrams: (route, router, params = {}, query = {}) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitChangePrams', route);
            return false;
        }
        router.push({
            name: route.name,
            params: {
                ...route.params,
                ...params,
            },
            query: {...route.query, ...query}
        })
    },
    setQueryParams: (route, router, query) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('setQueryParams', route);
            return false;
        }
        router.push({
            name: route.name,
            params: {
                ...route.params,
            },
            query
        })
    },
    smartSearch: (route, router, filter = {}, query = {}) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('smartSearch', route);
            return false;
        }
        router.push({
            name: consts.routerNames.units.type.xvision.smartSearch.main,
            params: {
                ...route.params,
                ...filter,
            },
            query: {
                ...query
            }
        })
    },
    smartSearchRequest: (route, router, unitId, eventXid) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('video-request', route);
            return false
        }
        let isLite = route.name.split('.').includes('lite')
        let noList = route.params.noList
        router.push({
            name: consts.routerNames.units.type.xvision.smartSearch.videoRequest + (isLite ? '.lite' : ''),
            params: {
                unitId,
                eventXid,
                noList
            },
            query: {...route.query}
        })
    },
    obdMap: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if(this.$isDevelopment) console.warn('obdMap', route);
            return false;
        }
        if(store.getters?.unitsOnMap?.length) {
            store.commit('setUnitsShowOnMap', {
                showOnMap: false,
                unitIds: store.getters?.unitsOnMap
            })
        }
        let isLicenseActive = store.getters.getUnitLicenseById[unitId]?.isActive
        if(!isLicenseActive) return false;

        let routeParams = getUnitDefaultRouteParams(unitId)

        router.push({
            name: routeParams?.routeName.map,
            params: {
                ...routeParams?.query,
            },
            query: {...route.query}
        })
    },

}

const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.portal') ? [] : [
    {
        path: 'units/:noList(no-list)?',
        name: consts.routerNames.units.main,
        meta: {
            list: 'UnitsList',
            mainRouteName: consts.routerNames.units.main,
            showMapInfo: false,
            showMap: true,
            weatherInMap: true,
            unitTracking: true,
            isLimited: true,
            layers: consts.defaultSettingsLayers,
            pageLayout: 'block-position-unit',
            setRoute: defaultSetRoute,
        },
        props: {
            default: () => (route) => ({
                unitId: route.params.unitId
            })
        },
        components: {
            default: () => import('@/views/intuit/portal/UnitsPage.vue'),
        },
        children: [
            {
                path: 'all',
                name: consts.routerNames.units.all.main,
                meta: {
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    weatherInMap: true,
                    pageClass: 'only-map',
                    layers: {
                        ...consts.defaultSettingsLayers,
                        units: {visible: true, showInList: true, showLayer: true},
                        geoitems: {visible: false, showInList: true, showLayer: true},
                    }
                },
                children: [
                    {
                        path: ':unitId(\\d+)/info',
                        name: consts.routerNames.units.all.info,
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.all.main,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                        }
                    },
                ]
            },
            {
                path: ':unitId(\\d+)',
                name: consts.routerNames.units.id,
                meta: {
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    weatherInMap: true,
                    layers: {
                        ...consts.defaultSettingsLayers,
                        units: {visible: true, showInList: true, showLayer: true},
                        geoitems: {visible: false, showInList: true, showLayer: true},
                    }
                    // pageClass: 'full-size-window'
                },
                children: [
                    {
                        path: 'xtrack',
                        name: consts.routerNames.units.type.xtrack.main,
                        meta: {
                            pageClass: 'unit__xtrack',
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.main,
                            weatherInMap: true,
                            layers: {
                                ...consts.defaultSettingsLayers,
                                units: {visible: true, showInList: true, showLayer: true},
                                geoitems: {visible: false, showInList: true, showLayer: true},
                            }
                        },
                        props: {
                            default: (route) => ({
                                unitId: route.params.unitId
                            })
                        },
                        components: {
                            default: () => import("@/components/units/pages/UnitPage_xtrack.vue"),
                        },
                        children: [
                            {
                                path: 'lite',
                                name: consts.routerNames.units.type.xtrack.main + '.lite',
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xtrack.main,
                                    weatherInMap: true,
                                    pageClass: 'unit__xtrack unit-lite unit-lite__xtrack',
                                },
                            },
                            {
                                path: 'edit',
                                name: consts.routerNames.units.type.xtrack.edit,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xtrack.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: route.params.unitId,
                                        time: Date.now(),
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                                }
                            },
                            {
                                path: 'info',
                                name: consts.routerNames.units.type.xtrack.info,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xtrack.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                                }
                            },
                            {
                                path: 'fast-geozone',
                                name: consts.routerNames.units.type.xtrack.fastGeozone,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xtrack.main,
                                    sectionInfoByRoute: true,
                                    fastGeozone: true,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: true, showInList: true, showLayer: true},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        geoitem_edit: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: +route.params?.unitId,
                                        time: Date.now()
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                                }
                            },
                            {
                                path: ':date(\\d{4}-\\d{2}-\\d{2})?/:type(parking|driving)?/:trackXid?/:timeFrom(\\d+)?/:timeTo(\\d+)?/:trackColor(track-color)?/:speed(\\d+)?',
                                name: consts.routerNames.units.type.xtrack.track,
                                meta: {
                                    unitTracking: false,
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xtrack.main,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: true, showLayer: true},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        tracks: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {},
                                components: {},
                                children: []
                            },
                            {
                                path: 'event/:eventXid',
                                name: consts.routerNames.units.type.xtrack.event,
                                meta: {
                                    mainRouteName: consts.routerNames.units.type.xtrack.main,
                                    parentRouteName: consts.routerNames.units.type.xtrack.main,
                                    unitTracking: false,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: false, showLayer: false},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        notifications: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {
                                },
                                components: {
                                },
                            },
                        ],
                    },
                    {
                        path: 'm2m',
                        name: consts.routerNames.units.type.m2m.main,
                        meta: {
                            pageClass: 'unit__xtrack',
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.main,
                            weatherInMap: true,
                            layers: {
                                ...consts.defaultSettingsLayers,
                                units: {visible: true, showInList: true, showLayer: true},
                                geoitems: {visible: false, showInList: true, showLayer: true},
                            }
                        },
                        props: {
                            default: (route) => ({
                                unitId: route.params.unitId
                            })
                        },
                        components: {
                            default: () => import("@/components/units/pages/UnitPage_m2m.vue"),
                        },
                        children: [
                            {
                                path: 'lite',
                                name: consts.routerNames.units.type.m2m.main + '.lite',
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.m2m.main,
                                    weatherInMap: true,
                                    pageClass: 'unit__xtrack unit-lite unit-lite__xtrack',
                                },
                            },
                            {
                                path: 'edit',
                                name: consts.routerNames.units.type.m2m.edit,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.m2m.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: route.params.unitId,
                                        time: Date.now(),
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                                }
                            },
                            {
                                path: 'info',
                                name: consts.routerNames.units.type.m2m.info,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.m2m.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                                }
                            },
                            {
                                path: 'fast-geozone',
                                name: consts.routerNames.units.type.m2m.fastGeozone,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.m2m.main,
                                    sectionInfoByRoute: true,
                                    fastGeozone: true,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: true, showInList: true, showLayer: true},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        geoitem_edit: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: +route.params?.unitId,
                                        time: Date.now()
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                                }
                            },
                            {
                                path: ':date(\\d{4}-\\d{2}-\\d{2})?/:type(parking|driving)?/:trackXid?/:timeFrom(\\d+)?/:timeTo(\\d+)?/:trackColor(track-color)?/:speed(\\d+)?',
                                name: consts.routerNames.units.type.m2m.track,
                                meta: {
                                    unitTracking: false,
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.m2m.main,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: true, showLayer: true},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        tracks: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {},
                                components: {},
                                children: []
                            },
                            {
                                path: 'event/:eventXid',
                                name: consts.routerNames.units.type.m2m.event,
                                meta: {
                                    mainRouteName: consts.routerNames.units.type.m2m.main,
                                    parentRouteName: consts.routerNames.units.type.m2m.main,
                                    unitTracking: false,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: false, showLayer: false},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        notifications: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {
                                },
                                components: {
                                },
                            },
                        ],
                    },
                    {
                        path: 'xvision',
                        name: consts.routerNames.units.type.xvision.main,
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.id,
                            pageClass: 'unit__xvision',
                            showMapInfo: false,
                            weatherInMap: true,
                            isVideo: true,
                            layers: {
                                ...consts.defaultSettingsLayers,
                                units: {visible: true, showInList: true, showLayer: true},
                                geoitems: {visible: false, showInList: true, showLayer: true},
                            }
                        },
                        props: {
                            default: (route) => ({unitId: route.params.unitId})
                        },
                        components: {
                            default: () => import("@/components/units/pages/UnitPage_xvision.vue"),
                        },
                        children: [
                            {
                                path: 'lite',
                                name: consts.routerNames.units.type.xvision.main + '.lite',
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xvision.main,
                                    pageClass: 'unit__xvision unit-lite unit-lite__xvision',
                                    weatherInMap: true
                                },
                            },
                            {
                                path: 'edit',
                                name: consts.routerNames.units.type.xvision.edit,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xvision.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: route.params.unitId,
                                        time: Date.now(),
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                                }
                            },
                            {
                                path: 'info',
                                name: consts.routerNames.units.type.xvision.info,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xvision.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                                }
                            },
                            {
                                path: 'fast-geozone',
                                name: consts.routerNames.units.type.xvision.fastGeozone,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xvision.main,
                                    sectionInfoByRoute: true,
                                    fastGeozone: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: +route.params?.unitId,
                                        time: Date.now()
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                                }
                            },
                            {
                                path: ':date(\\d{4}-\\d{2}-\\d{2})?/:type(parking|driving)?/:trackXid?/:timeFrom(\\d+)?/:timeTo(\\d+)?/:trackColor(track-color)?/:speed(\\d+)?',
                                name: consts.routerNames.units.type.xvision.track,
                                meta: {
                                    unitTracking: false,
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xvision.main,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: true, showLayer: true},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        tracks: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {},
                                components: {},
                                children: []
                            },
                            {
                                path: 'event/:eventXid',
                                name: consts.routerNames.units.type.xvision.event,
                                meta: {
                                    mainRouteName: consts.routerNames.units.type.xvision.main,
                                    parentRouteName: consts.routerNames.units.type.xvision.main,
                                    unitTracking: false,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: false, showLayer: false},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        notifications: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {
                                },
                                components: {
                                },
                            },
                            {
                                path: 'live-video',
                                name: consts.routerNames.units.type.xvision.liveVideo,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xvision.main,
                                    pageClass: 'unit__xvision unit__xvision-video playback-hide',
                                    showMapInfo: false
                                },
                                props: {
                                    LiveVideoComponent: (route) => ({unitId: route.params.unitId})
                                },
                                components: {
                                    LiveVideoComponent: () => import('@/components/video/control/live-video/LiveVideo.vue'),
                                },
                                children: [
                                    {
                                        path: 'lite',
                                        name: consts.routerNames.units.type.xvision.liveVideo + '.lite',
                                        meta: {
                                            mainRouteName: consts.routerNames.units.main,
                                            parentRouteName: consts.routerNames.units.type.xvision.main,
                                            pageClass: 'unit__xvision unit-lite unit-lite__xvision unit-lite__xvision__video',
                                            weatherInMap: true
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'video-event/:eventXid',
                                name: consts.routerNames.units.type.xvision.videoEvent,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xvision.main,
                                    pageClass: 'unit__xvision unit__xvision-video',
                                    showMapInfo: false,
                                },
                                props: {
                                    VideoEventComponent: (route) => ({eventXid: route.params.eventXid})
                                },
                                components: {
                                    VideoEventComponent: () => import('@/components/video/control/video-event/VideoEvent.vue')
                                },
                                children: [
                                    {
                                        path: 'lite',
                                        name: consts.routerNames.units.type.xvision.videoEvent + '.lite',
                                        meta: {
                                            mainRouteName: consts.routerNames.units.main,
                                            parentRouteName: consts.routerNames.units.type.xvision.main,
                                            pageClass: 'unit__xvision unit-lite unit-lite__xvision unit-lite__xvision__video',
                                            weatherInMap: true
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'snapshots/:eventXid',
                                name: consts.routerNames.units.type.xvision.snapshots,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xvision.main,
                                    pageClass: 'unit__xvision unit__xvision-video',
                                    showMapInfo: false,
                                },
                                props: {
                                    VideoEventComponent: (route) => ({
                                        unitId: route.params.unitId,
                                        eventXid: route.params.eventXid
                                    })
                                },
                                components: {
                                    VideoEventComponent: () => import('@/components/units/info/UnitInfo_screenWall.vue'),
                                },
                                children: [
                                    {
                                        path: 'lite',
                                        name: consts.routerNames.units.type.xvision.snapshots + '.lite',
                                        meta: {
                                            mainRouteName: consts.routerNames.units.main,
                                            parentRouteName: consts.routerNames.units.type.xvision.main,
                                            pageClass: 'unit__xvision unit-lite unit-lite__xvision unit-lite__xvision__video',
                                            weatherInMap: true
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'video-request/:eventXid',
                                name: consts.routerNames.units.type.xvision.videoRequest,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xvision.main,
                                    pageClass: 'unit__xvision unit__xvision-video playback-hide',
                                    isVideo: true,
                                    showMapInfo: false
                                },
                                props: {
                                    VideoEventComponent: (route) => ({eventXid: route.params.eventXid})
                                },
                                components: {
                                    VideoEventComponent: () => import('@/components/video/control/video-request/VideoRequest.vue')
                                },
                                children: [
                                    {
                                        path: 'lite',
                                        name: consts.routerNames.units.type.xvision.videoRequest + '.lite',
                                        meta: {
                                            mainRouteName: consts.routerNames.units.main,
                                            parentRouteName: consts.routerNames.units.type.xvision.videoRequest,
                                            pageClass: 'unit__xvision unit-lite unit-lite__xvision unit-lite__xvision__video',
                                            weatherInMap: true
                                        },
                                    },
                                ]
                            },
                            // {
                            //     path: 'smart-search/:mode(time|geozone)?/:from(\\d+)?/:to(\\d+)?',
                            //     name: consts.routerNames.units.type.xvision.smartSearch,
                            //     meta: {
                            //         unitTracking: false,
                            //         mainRouteName: consts.routerNames.units.main,
                            //         parentRouteName: consts.routerNames.units.type.xvision,
                            //     },
                            //     props: {
                            //     },
                            //     components: {
                            //     },
                            //     children: [
                            //         {
                            //             path: 'video-request/:eventXid',
                            //             name: consts.routerNames.units.type.xvision.smartSearch + '.video-request',
                            //             meta: {
                            //                 mainRouteName: consts.routerNames.units.main,
                            //                 parentRouteName: consts.routerNames.units.type.xvision.smartSearch,
                            //                 pageClass: 'unit__xvision unit__xvision-video playback-hide',
                            //                 isVideo: true,
                            //                 showMapInfo: false
                            //             },
                            //             props: {
                            //                 default: (route) => ({eventXid: route.params.eventXid})
                            //             },
                            //             components: {
                            //                 default: () => import('@/components/video/control/video-request/VideoRequest.vue')
                            //             },
                            //         },
                            //     ]
                            // },
                        ],
                    },
                    {
                        path: 'xcool',
                        name: consts.routerNames.units.type.xcool.main,
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.main,
                            layers: {
                                ...consts.defaultSettingsLayers,
                                units: {visible: true, showInList: true, showLayer: true},
                                geoitems: {visible: false, showInList: true, showLayer: true},
                            },
                            componentsSettings: {
                                UnitInfo__hardware: {
                                    class: 'hide-car-info'
                                },
                                UnitInfo__address: {
                                    class: 'hide-car-info'
                                }
                            },
                            weatherInMap: true,
                        },
                        props: {
                            default: (route) => ({
                                unitId: route.params.unitId,
                            })
                        },
                        components: {
                            default: () => import("@/components/units/pages/UnitPage_xcool.vue"),
                        },
                        children: [
                            {
                                path: 'lite',
                                name: consts.routerNames.units.type.xcool.main + '.lite',
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xcool.main,
                                    pageClass: 'unit-lite unit-lite__xcool',
                                },
                            },
                            {
                                path: 'edit',
                                name: consts.routerNames.units.type.xcool.edit,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xcool.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: route.params.unitId,
                                        time: Date.now(),
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                                }
                            },
                            {
                                path: 'info',
                                name: consts.routerNames.units.type.xcool.info,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xcool.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                                }
                            },
                            {
                                path: 'event/:eventXid',
                                name: consts.routerNames.units.type.xcool.event,
                                meta: {
                                    mainRouteName: consts.routerNames.units.type.xcool.main,
                                    parentRouteName: consts.routerNames.units.type.xcool.main,
                                    unitTracking: false,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: false, showLayer: false},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        notifications: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {
                                },
                                components: {
                                },
                            },
                            {
                                path: 'fast-geozone',
                                name: consts.routerNames.units.type.xcool.fastGeozone,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xcool.main,
                                    sectionInfoByRoute: true,
                                    fastGeozone: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: +route.params?.unitId,
                                        time: Date.now()
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                                }
                            },
                            {
                                path: 'reefer',
                                name: consts.routerNames.units.type.xcool.reefer,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xcool.main,
                                    pageClass: 'reefer-active map-bottom-right',
                                },
                                props: {
                                    UnitControl_reefer: (route) => ({
                                        unitId: +route.params?.unitId,
                                    })
                                },
                                components: {
                                    UnitControl_reefer: () => import('@/components/units/control/UnitControl_reefer.vue'),
                                }
                            },
                            {
                                path: ':date(\\d{4}-\\d{2}-\\d{2})?/:type(parking|driving)?/:trackXid?/:timeFrom(\\d+)?/:timeTo(\\d+)?/:trackColor(track-color)?/:speed(\\d+)?',
                                name: consts.routerNames.units.type.xcool.track,
                                meta: {
                                    unitTracking: false,
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xcool.main,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: true, showLayer: true},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        tracks: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {},
                                components: {},
                                children: []
                            },
                        ],
                    },
                    {
                        path: 'xpower',
                        name: consts.routerNames.units.type.xpower.main,
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.main,
                            pageClass: 'unit-generator-page unit-generator-main-page',
                            // weatherInMap: true,
                            layers: {
                                ...consts.defaultSettingsLayers,
                                units: {visible: true, showInList: true, showLayer: true},
                                geoitems: {visible: false, showInList: true, showLayer: true},
                            }
                        },
                        props: {
                            default: (route) => ({
                                unitId: route.params.unitId,
                                sensors: ['last_message', 'phaseAB', 'phaseBC', 'phaseCA', 'fuel_level'],
                            }),
                        },
                        components: {
                            default: () => import("@/components/units/pages/UnitPage_xpower.vue"),
                        },
                        children: [
                            {
                                path: 'lite',
                                name: consts.routerNames.units.type.xpower.main + '.lite',
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xpower.main,
                                    // pageClass: 'unit-lite unit-lite__xpower',
                                },
                            },
                            {
                                path: 'edit',
                                name: consts.routerNames.units.type.xpower.edit,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xpower.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: route.params.unitId,
                                        time: Date.now(),
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                                }
                            },
                            {
                                path: 'info',
                                name: consts.routerNames.units.type.xpower.info,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xpower.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                                }
                            },
                            {
                                path: 'event/:eventXid',
                                name: consts.routerNames.units.type.xpower.event,
                                meta: {
                                    mainRouteName: consts.routerNames.units.type.xpower.main,
                                    parentRouteName: consts.routerNames.units.type.xpower.main,
                                    unitTracking: false,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: false, showLayer: false},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        notifications: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {
                                },
                                components: {
                                },
                            },
                            {
                                path: 'fast-geozone',
                                name: consts.routerNames.units.type.xpower.fastGeozone,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xpower.main,
                                    sectionInfoByRoute: true,
                                    fastGeozone: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: +route.params?.unitId,
                                        time: Date.now()
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                                }
                            },
                            {
                                path: ':date(\\d{4}-\\d{2}-\\d{2})?/:type(parking|driving)?/:trackXid?/:timeFrom(\\d+)?/:timeTo(\\d+)?/:trackColor(track-color)?/:speed(\\d+)?',
                                name: consts.routerNames.units.type.xpower.track,
                                meta: {
                                    unitTracking: false,
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xpower.main,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: true, showLayer: true},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        tracks: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {},
                                components: {},
                                children: []
                            },
                            {
                                path: 'control',
                                name: consts.routerNames.units.type.xpower.control,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xpower.main,
                                    pageClass: 'unit-generator-page unit-generator-main-page',
                                },
                                props: {
                                    main: (route) => ({
                                        unitId: route.params.unitId,
                                    })
                                },
                                components: {
                                    main: () => import('@/components/units/info/UnitInfo_generator_main.vue'),
                                },
                                children: [
                                    {
                                        path: 'lite',
                                        name: consts.routerNames.units.type.xpower.control + '.lite',
                                        meta: {
                                            mainRouteName: consts.routerNames.units.main,
                                            parentRouteName: consts.routerNames.units.type.xpower.control,
                                            // pageClass: 'unit-lite unit-lite__xpower',
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'analytics',
                                name: consts.routerNames.units.type.xpower.analytics,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xpower.main,
                                    pageClass: 'unit-generator-page unit-generator-analytics-page',
                                },
                                props: {
                                    main: (route) => ({
                                        unitId: route.params.unitId,
                                        grafanaSensors: ['phaseAB', 'phaseBC', 'phaseCA', 'fuel_lvl'],
                                    })
                                },
                                components: {
                                    main: () => import('@/components/units/info/UnitInfo_generator_analytics.vue'),
                                },
                                children: [
                                    {
                                        path: 'lite',
                                        name: consts.routerNames.units.type.xpower.analytics + '.lite',
                                        meta: {
                                            mainRouteName: consts.routerNames.units.main,
                                            parentRouteName: consts.routerNames.units.type.xpower.analytics,
                                            // pageClass: 'unit-lite unit-lite__xpower',
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'history',
                                name: consts.routerNames.units.type.xpower.history,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xpower.main,
                                    pageClass: 'unit-generator-page unit-generator-history-page',
                                },
                                props: {
                                    main: (route) => ({
                                        unitId: route.params.unitId,
                                    })
                                },
                                components: {
                                    main: () => import('@/components/units/info/UnitInfo_generator_history.vue'),
                                },
                                children: [
                                    {
                                        path: 'lite',
                                        name: consts.routerNames.units.type.xpower.history + '.lite',
                                        meta: {
                                            mainRouteName: consts.routerNames.units.main,
                                            parentRouteName: consts.routerNames.units.type.xpower.history,
                                            // pageClass: 'unit-lite unit-lite__xpower',
                                        },
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        path: 'xgrafana',
                        name: consts.routerNames.units.type.xgrafana.main,
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.main,
                            pageClass: 'unit-graph map-bottom-right',
                            weatherInMap: false,
                            mapType: 'Google-Hybrid',
                            layers: {
                                ...consts.defaultSettingsLayers,
                                units: {visible: true, showInList: true, showLayer: true},
                                track_by_points: {visible: true, showInList: true, showLayer: true},
                            },
                            componentsSettings: {
                                UnitInfo__sensors: {
                                    sensors: [
                                        'last_message',
                                        'speed',
                                        'status',
                                        'main_power',
                                        'battery',
                                        'inputs',
                                        'outputs',
                                    ],
                                }
                            },
                        },
                        props: {
                            default: (route) => ({
                                unitId: route.params.unitId,
                                //sensors: ['temperature', 'humidity', 'light', 'battery', 'shock'],
                            })
                        },
                        components: {
                            default: () => import("@/components/units/pages/UnitPage_xgrafana.vue"),
                        },
                        children: [
                            {
                                path: 'lite',
                                name: consts.routerNames.units.type.xgrafana.main + '.lite',
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xgrafana.main,
                                    pageClass: 'unit-lite unit-lite__xgrafana',
                                    weatherInMap: true,
                                },
                            },
                            {
                                path: 'edit',
                                name: consts.routerNames.units.type.xgrafana.edit,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xgrafana.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: route.params.unitId,
                                        time: Date.now(),
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                                }
                            },
                            {
                                path: 'info',
                                name: consts.routerNames.units.type.xgrafana.info,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xgrafana.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                                }
                            },
                            {
                                path: 'event/:eventXid',
                                name: consts.routerNames.units.type.xgrafana.event,
                                meta: {
                                    mainRouteName: consts.routerNames.units.type.xgrafana.main,
                                    parentRouteName: consts.routerNames.units.type.xgrafana.main,
                                    unitTracking: false,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: false, showLayer: false},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        notifications: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {
                                },
                                components: {
                                },
                            },
                            {
                                path: 'fast-geozone',
                                name: consts.routerNames.units.type.xgrafana.fastGeozone,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xgrafana.main,
                                    sectionInfoByRoute: true,
                                    fastGeozone: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: +route.params?.unitId,
                                        time: Date.now()
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                                }
                            },
                            {
                                path: ':date(\\d{4}-\\d{2}-\\d{2})?/:type(parking|driving)?/:trackXid?/:timeFrom(\\d+)?/:timeTo(\\d+)?/:trackColor(track-color)?/:speed(\\d+)?',
                                name: consts.routerNames.units.type.xgrafana.track,
                                meta: {
                                    unitTracking: false,
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xgrafana.main,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: true, showLayer: true},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        tracks: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {},
                                components: {},
                                children: []
                            },
                            {
                                path: 'map',
                                name: consts.routerNames.units.type.xgrafana.map,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xgrafana.main,
                                    pageClass: 'unit-graph unit-graph__xgrafana-map',
                                    weatherInMap: true,
                                },
                            },
                        ],
                    },
                    {
                        path: 'xlink',
                        name: consts.routerNames.units.type.xlink.main,
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.main,
                            pageClass: 'section-unit-xlink',
                            weatherInMap: false,
                            showMapInfo: true,
                            layers: {
                                ...consts.defaultSettingsLayers,
                                units: {visible: true, showInList: true, showLayer: true},
                                geoitems: {visible: false, showInList: true, showLayer: true},
                            },
                            componentsSettings: {
                                UnitInfo__sensors: {
                                    class: 'sensors-xlink'
                                }
                            },
                        },
                        props: {
                            default: (route) => ({
                                unitId: route.params.unitId,
                                sensors: ['name', 'mac', 'connection_type', 'last_message', 'battery_voltage', 'temperature', 'humidity'],
                            })
                        },
                        components: {
                            default: () => import("@/components/units/pages/UnitPage_xlink.vue"),
                        },
                        children: [
                            {
                                path: 'event/:eventXid',
                                name: consts.routerNames.units.type.xlink.event,
                                meta: {
                                    mainRouteName: consts.routerNames.units.type.xlink.main,
                                    parentRouteName: consts.routerNames.units.type.xlink.main,
                                    unitTracking: false,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: false, showLayer: false},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        notifications: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {
                                },
                                components: {
                                },
                            },
                            {
                                path: 'lite',
                                name: consts.routerNames.units.type.xlink.main + '.lite',
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xlink.main,
                                    weatherInMap: true,
                                    pageClass: 'section-unit-xlink unit-lite unit-lite__xlink',
                                },
                            },
                            {
                                path: 'edit',
                                name: consts.routerNames.units.type.xlink.edit,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xlink.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: route.params.unitId,
                                        time: Date.now(),
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                                }
                            },
                            {
                                path: 'fast-geozone',
                                name: consts.routerNames.units.type.xlink.fastGeozone,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xlink.main,
                                    sectionInfoByRoute: true,
                                    fastGeozone: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: +route.params?.unitId,
                                        time: Date.now()
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                                }
                            },
                            {
                                path: ':date(\\d{4}-\\d{2}-\\d{2})?/:type(parking|driving)?/:trackXid?/:timeFrom(\\d+)?/:timeTo(\\d+)?/:trackColor(track-color)?/:speed(\\d+)?',
                                name: consts.routerNames.units.type.xlink.track,
                                meta: {
                                    unitTracking: false,
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xlink.main,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: true, showLayer: true},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        tracks: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {},
                                components: {},
                                children: []
                            },
                            // {
                            //     path: 'analytics',
                            //     name: consts.routerNames.units.type.xlink + '.analytics',
                            //     meta: {
                            //         mainRouteName: consts.routerNames.units.main,
                            //         parentRouteName: consts.routerNames.units.type.xlink,
                            //         pageClass: 'section-unit-links-lab section-unit-links-lab-analytics map-bottom-right',
                            //     },
                            //     props:{
                            //         analytics: (route) => ({
                            //             unitId: route.params.unitId
                            //         }),
                            //         sectionInfoByRoute: (route) => ({
                            //             unitId: route.params.unitId,
                            //         })
                            //     },
                            //     components: {
                            //         analytics: () => import('@/components/units/info/UnitInfo_station_analytics.vue'),
                            //         sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                            //     }
                            // }
                        ]
                    },
                    {
                        path: 'obd',
                        name: consts.routerNames.units.type.obd.main,
                        meta:{
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.main,
                            pageClass: 'unit-obd',
                            showMapInfo: false,
                            weatherInMap: true,
                        },
                        props: {
                            default: (route) => ({unitId: route.params.unitId})
                        },
                        components: {
                            default: () => import("@/components/units/pages/UnitPage_OBD.vue"),
                        },
                        children:[
                            {
                                path: 'event/:eventXid',
                                name: consts.routerNames.units.type.obd.event,
                                meta: {
                                    mainRouteName: consts.routerNames.units.type.obd.main,
                                    parentRouteName: consts.routerNames.units.type.obd.main,
                                    unitTracking: false,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: false, showLayer: false},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        notifications: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {
                                },
                                components: {
                                },
                            },
                            {
                                path: 'lite',
                                name: consts.routerNames.units.type.obd.main + '.lite',
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.obd.main,
                                    pageClass: 'unit-obd unit-lite unit-lite__obd',
                                    weatherInMap: true
                                },
                            },
                            {
                                path: 'edit',
                                name: consts.routerNames.units.type.obd.edit,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.obd.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: route.params.unitId,
                                        time: Date.now(),
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                                }
                            },
                            {
                                path: 'info',
                                name: consts.routerNames.units.type.obd.info,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.obd.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                                }
                            },
                            {
                                path: 'fast-geozone',
                                name: consts.routerNames.units.type.obd.fastGeozone,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.obd.main,
                                    sectionInfoByRoute: true,
                                    fastGeozone: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: +route.params?.unitId,
                                        time: Date.now()
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                                }
                            },
                        ],
                    },
                ],
            },
        ],
        // beforeEnter: (to, from) => {
        //     if(from.name && from.name != 'Login'){// First load not correct getUserStorageKey == 0
        //         let unitsIdPage = {name: 'Units', params: {unitId: localStorage.getItem(store.getters.getUserStorageKey+'.unit.active')}}
        //         if(to.name == 'Units' && !to?.params?.unitId) {
        //             return to?.params?.unitId ? to : unitsIdPage
        //         } else{
        //             return true
        //         }
        //     } else
        //     if(to?.params?.unitId) {// First load
        //         return {...to, params: {}}
        //     }
        // },
        // Vuex version !!!
        // beforeEnter: (to) => {
        //     // let unitId = localStorage.getItem(this.getUserStorageKey + '.unit.active')*1
        //     let unitId = to.params?.unitId
        //     if (!unitId) {
        //         unitId = localStorage.getItem(store.getters.getUserStorageKey+'.unit.active')*1
        //         to.params.unitId = unitId
        //     }
        //     //return false
        // },
    },
];

export default routes