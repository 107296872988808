// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
import {getMainParentRouteName} from "@/lib/lib";
const pageClass = 'reports-page';

const defaultSetRoute = {
    reportTemplate: (route, router, template) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.reports.main) {
            if(this.$isDevelopment) console.warn('reports', route);
            return false;
        }
        router.push({
            name: consts.routerNames.reports.template,
            params: {
                template
            },
            query: {...route.query}
        })
    },
    reportSystem: (route, router, report) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.reports.main) {
            if(this.$isDevelopment) console.warn('reports', route);
            return false;
        }
        router.push({
            name: consts.routerNames.reports.system,
            params: {
                template: report.id_report,
                timeFrom: report.time_from,
                timeTo: report.time_to,
                objectType: report.object_type,
                objectId: report.object_id,
            },
            query: {...route.query}
        })
    },
    reportMongo: (route, router, reportId) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.reports.main) {
            if(this.$isDevelopment) console.warn('reports', route);
            return false;
        }
        router.push({
            name: consts.routerNames.reports.mongo,
            params: {
                reportId
            },
            query: {...route.query}
        })
    }
}

const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.portal') ? [] : [
    {
        path: 'reports',
        name: consts.routerNames.reports.main,
        meta: {
            mainRouteName: consts.routerNames.reports.main,
            pageClass,
            layers: consts.defaultSettingsLayers,
            setRoute: defaultSetRoute,
        },
        component: () => import('@/views/intuit/portal/ReportsPage.vue'),
        children: [
            {
                path: ':template',
                name: consts.routerNames.reports.template,
                meta: {
                    mainRouteName: consts.routerNames.reports.main,
                },
                props: {
                    default: (route) => {
                        return {
                            template: route.params?.template
                        }
                    }
                },
                components:{
                    default: () => import("@/components/reports/ReportItem_info.vue"),
                }
            },
            {
                path: ':template/:timeFrom(\\d+)/:timeTo(\\d+)/:objectType/:objectId',
                name: consts.routerNames.reports.system,
                meta: {
                    mainRouteName: consts.routerNames.reports.main,
                    pageClass: pageClass+'__id'
                },
                props: {
                    default: (route) => {
                        return {
                            template: route.params?.template,
                            timeFrom: route.params?.timeFrom,
                            timeTo: route.params?.timeTo,
                            objectType: route.params?.objectType,
                            objectId: route.params?.objectId,
                        }
                    }
                },
                components:{
                    default: () => import("@/components/reports/ReportSystem.vue"),
                }
            },
            {
                path: ':reportId',
                name: consts.routerNames.reports.mongo,
                meta: {
                    mainRouteName: consts.routerNames.reports.main,
                    pageClass: pageClass+'__id'
                },
                props: {
                    default: (route) => {
                        return {
                            reportId: route.params?.reportId
                        }
                    }
                },
                components:{
                    default: () => import("@/components/reports/ReportMongo.vue"),
                }
            }
        ]
    },
];

export default routes;